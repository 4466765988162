/**
 * Blogs 小猪宰汁
 * Date: 2023/3/2
 * filename：en.js
 */
export const h = {
    1: "Get Started",
    2: "BUY AND SELL",
    3: "Buy Bitcoin, Ethereum, and 350+ cryptocurrencies",
    4: "Own the culture you love",
    5: "Prime",
    6: "Build your generational wealth",
    7: "SPEND",
    8: "Metal Visa Cards",
    9: "Up to 5% back on all spending",
    10: "Crypto.com Pay",
    11: "Spend your crypto anywhere",
    12: "GROW",
    13: "Rewards",
    14: "Make every trade more rewarding",
    15: "Crypto Earn",
    16: "Get the most out of your assets",
    17: "On-chain Staking",
    18: "Generate passive income",
    19: "DeFi Staking",
    20: "Access DeFi protocols and get rewarded",
    21: "Advanced Trading",
    22: "EXCHANGE",
    23: "Exchange Home",
    24: "Trade with low fees and deep liquidity",
    25: "Margin Trading",
    26: "Get 3x leverage",
    27: "Derivatives Trading",
    28: "20x leverage, ultra low latency",
    29: "DEFI",
    30: "DeFi Wallet",
    31: "One app, multiple DeFi services",
    32: "Browser Extension",
    33: "Access DeFi protocols on desktop",
    34: "Desktop Wallet",
    35: "Easily manage your DeFi tools",
    36: "ECOSYSTEM",
    37: "Pay for Business",
    38: "Accept crypto simply and securely",
    39: "Download App",
    40: "Scan to Download",
    41: "The World’s",
    42: "Premier",
    43: "Trading Platform",
    44: "Buy Bitcoin, Ethereum, and 350+ cryptocurrencies",
    45: "Download App",
    46: "Your crypto journey starts here",
    47: "Buy crypto",
    48: "Buy BTC, ETH, and other crypto easily via bank transfer.",
    49: "Buy BTC, ETH, and other crypto easily",
    50: "with",
    51: "Price Alerts",
    52: "Be notified on BTC, ETH, XRP prices, and more.",
    53: "On-chain Staking",
    54: "Generate passive income by helping",
    55: "to secure blockchains.",
    56: "Join our",
    57: "users",
    58: "Get started today",
    59: "Scan",
    60: "to download",
    61: "the app",
    62: "Join our",
    63: "users",
    64: "Get started today",
    65: "Buy crypto",
    66: "Buy BTC, ETH, and other crypto easily via bank transfer.",
    67: "Buy BTC, ETH, and other crypto easily",
    68: "with",
    69: "Price Alerts",
    70: "Be notified on BTC, ETH, XRP prices, and more.",
    71: "Recurring Buy",
    72: "Grow your portfolio automatically with daily, weekly, or monthly  trades.",
    73: "On-chain Staking",
    74: "Generate passive income by helping",
    75: "to secure blockchains.",
    76: "Join our",
    77: "users",
    78: "Get started today",
    79: "Sorry, your browser doesn't support embedded videos.",
    80: "CRYPTO.COM VISA CARD",
    81: "The only crypto card you need",
    82: "Enjoy up to 5% back on all spending with your sleek, pure metal card.",
    83: "No annual fees. Top-up with fiat or crypto.",
    84: "Choose Your Card",
    85: "CRYPTO EARN",
    86: "Get the most out of your assets, safely",
    87: "Choose from 21+ cryptocurrencies including Bitcoin and  stablecoins.",
    88: "Calculate Rewards",
    89: "EXCHANGE",
    90: "Trade bitcoin and other crypto with confidence on the world's fastest and most secure crypto exchange",
    91: "DESKTOP",
    92: "Go to Crypto.com Exchange",
    93: "MOBILE",
    94: "Download Exchange App",
    95: "Liquidity",
    96: "Deep order book liquidity in all market conditions",
    97: "Speed",
    98: "64 million TPS matching engine",
    99: "370-nanosecond core latency",
    100: "Security",
    101: "Singapore Data Protection Trust Mark",
    102: "OUR VISION",
    103: "Cryptocurrency in Every Wallet™",
    104: "Founded in",
    105: "Users",
    106: "About Us",
    108: "Price",
    107: "Frequently Asked Questions",
    109: "What is crypto",
    110: "Cryptocurrency is a digital or virtual currency that operates on distributed ledger technology called a ",
    111: "blockchain",
    112: "and uses",
    113: "cryptography",
    114: "for security. It is decentralized and operates independently of a central bank.",
    115: "Unlike traditional currencies, cryptocurrencies are not backed by a physical commodity or government, and their value is determined by market demand  and supply. Cryptocurrencies can be used to buy goods and services, transfer funds, and trade in markets. Popular cryptocurrencies include ",
    116: "Bitcoin",
    117: "thereum",
    118: "Ripple",
    119: "and",
    120: "Many cryptocurrencies, like Bitcoin, are created through a process called",
    121: "mining",
    122: "which involves solving complex mathematical equations to validate and record transactions on a blockchain. This mechanism is also called",
    123: "Proof of Work (PoW)",
    124: ". Another consensus mechanism that has increased in popularity — as it is more energy efficient — is",
    125: "Proof of Stake (PoS)",
    126: ". Instead of mining, PoS relies on network participants validating transactions. Ethereum, the second-largest cryptocurrency, uses this consensus mechanism.",
    127: "Where to buy crypto",
    128: "There are several ways to buy cryptocurrencies, including:",
    129: "Brokerage services:",
    130: "Crypto brokers allow users to simply buy and sell cryptocurrencies. A popular example is the",
    131: ", trusted by over 100 million users. It is available at the Apple Store and on Google Play.",
    132: "Cryptocurrency exchanges:",
    133: "These are online platforms where users can buy, sell, and trade cryptocurrencies using fiat currency or other cryptocurrencies. They offer more complex functions compared to a crypto brokerage, adding trading instruments like crypto derivatives. The ",
    134: "Exchange",
    135: "is an example of a popular crypto exchange.",
    136: "Peer-to-peer (P2P) marketplaces:",
    137: "These are platforms where buyers and sellers can directly trade cryptocurrencies without the involvement of a third-party exchange. This is also known as",
    138: "short for decentralized finance. Multiple P2P crypto marketplaces can be accessed all in one app via the",
    139: "DeFi Wallet",
    140: "It is important to perform proper research and choose a reputable platform to buy cryptocurrencies. For instance, Crypto.com holds the ",
    141: "highest security rating in the industry",
    142: " In addition, it is advisable to store cryptocurrencies securely in a wallet like the Crypto.com App or Crypto.com DeFi Wallet.",
    143: "How to buy crypto",
    144: "To buy crypto, follow these general steps",
    145: "Choose a crypto platform to use, like the ",
    146: "or",
    147: "Create an account on the chosen platform by providing personal information and ID verification, also known as ‘Know Your Customer’",
    148: "procedures",
    149: "Deposit fiat currency or another cryptocurrency into the newly created account. The Crypto.com App supports bank transfers, credit cards, debit cards, and cryptocurrency transfers to buy crypto, depending on region",
    150: "Navigate to the ‘Buy’ section of the Crypto.com Exchange or App and select the crypto to buy",
    151: "Enter the amount of cryptocurrency to buy and confirm the transaction",
    152: "The crypto will be deposited into the account. From here, it can be transferred to other crypto wallets or converted back to fiat currency and paid out to a bank  account",
    153: "It is important to perform proper research and choose a reputable platform to buy  cryptocurrencies. For instance, Crypto.com holds the ",
    154: "highest security  rating in the industry",
    155: "In addition, it is advisable to store cryptocurrencies securely in a wallet like the ",
    156: "or",
    157: "How to trade crypto",
    158: "To trade cryptocurrency, follow these general steps",
    159: "Choose a cryptocurrency exchange that supports trading. A popular option is the",
    160: "Create an account on the chosen platform and perform ID verification, known as",
    161: "Deposit funds into the newly created account using a supported payment method. The Crypto.com Exchange supports bank transfers and credit/debit cards.",
    162: "Navigate to the trading section of the platform and select the cryptocurrency pair to trade",
    163: "Choose whether to buy or sell the cryptocurrency, and enter the amount to trade",
    164: "Set the preferred price and order type. There are several types of orders, including market orders, limit orders, stop orders, and crypto options, which allow users to buy or sell at a specific price or under certain conditions",
    165: "Submit the trade order and wait for it to be executed. Depending on market conditions, the trade may be filled immediately, or it may take time to be filled",
    166: "Monitor trades and adjust strategies as necessary",
    167: "Here is an introduction to",
    168: "It is crucial to note that trading cryptocurrency carries risk, and it is important to trade only what you can afford to lose",
    169: "DeFi Wallet",
    170: "(‘Know Your Customer’)",
    171: "trading on the Crypto.com Exchange",
    172: "How to earn crypto",
    173: "There are several ways to earn cryptocurrency, including",
    174: "Mining",
    175: ": Cryptocurrency mining involves using specialized computer hardware to solve complex mathematical equations that validate transactions on a blockchain network. Successful miners are rewarded with newly minted cryptocurrency for their  efforts.",
    176: "Staking/Lockups",
    177: "Staking and lockups involve holding or locking up a certain amount of cryptocurrency in a wallet or on a platform to support the operations of the blockchain network. Stakers are rewarded with new cryptocurrency as a form of interest for their support.",
    178: "Trading",
    179: "Trading cryptocurrency involves buying and selling cryptocurrencies on exchanges or other trading platforms. Those who have a good understanding of market trends and are able to make informed trading decisions can earn profits through trading.",
    180: "Airdrops: Airdrops are free distributions of cryptocurrency to users who meet certain criteria or participate in promotional activities.",
    181: "Projects",
    182: "Some blockchain projects offer rewards or  bounties for users who contribute to their development or community. This can include activities like bug bounties, testing, or content creation.",
    183: "It's important to note that each method of earning cryptocurrency carries its own risks and rewards. It is recommended to carefully research cryptocurrencies and understand the process before buying. Learn more about the crypto market",
    184: "How to earn rewards on crypto",
    185: "Users can earn rewards on their cryptocurrency holdings through various products that offer rewards-bearing accounts or lending services. Here are some ways to earn rewards on cryptocurrency:",
    186: "The",
    187: "Exchange",
    188: "and",
    189: "DeFi Wallet",
    190: "all offer different  ways to earn rewards on crypto, called",
    191: "Create an account on the chosen platform and deposit cryptocurrency holdings into he rewards account.",
    192: "Depending on the platform, users may earn rewards through lending their cryptocurrency to other users or by locking up their cryptocurrency for a period of time.",
    193: "Some products offer fixed reward rates (e.g., the ",
    194: "Visa Card",
    195: "while others may offer variable rates that depend on market conditions (e.g., the 'Earn' feature in the Crypto.com DeFi Wallet).",
    196: "Monitor rewards and adjust strategies as necessary.",
    197: "It's important to note that earning interest and rewards on cryptocurrency carries risks, including fluctuations in market conditions that may affect interest rates. Make sure to carefully research and understand terms and conditions before depositing cryptocurrency.",
    198: "Have more questions",
    199: "Contact Us",
    200: "Get started with crypto",
    201: "Scan to Download",
    202: "Features",
    203: "Rewards",
    204: "Buy and Sell",
    205: "On-chain Staking",
    206: "Pay",
    207: "Supercharger",
    208: "Trading Arena",
    209: "Learn",
    210: "University",
    211: "Research &amp; Analysis",
    212: "Glossary",
    213: "What is Ethereum?",
    214: "What is Blockchain?",
    215: "How to buy Bitcoin?",
    216: "How to buy Ethereum?",
    217: "How to buy Crypto?",
    218: "What is Crypto?",
    219: "What is DeFi?",
    220: "Prices",
    221: "Converter",
    222: "Site Widgets",
    223: "What's Trending",
    224: "Market Updates",
    225: "Product News",
    226: "Company News",
    227: "Events",
    228: "Company",
    229: "About Us",
    230: "Partners",
    231: "Security",
    232: "Proof of Reserves",
    233: "Climate",
    234: "Capital",
    235: "Affiliate",
    236: "Careers",
    237: "Listing",
    238: "Support",
    239: "Features",
    240: "Rewards",
    241: "Buy and Sell",
    242: "Crypto Earn",
    243: "On-chain Staking",
    244: "Pay",
    245: "Pay for Business",
    246: "Margin Trading",
    247: "Derivatives Trading",
    248: "Supercharger",
    249: "Trading Arena",
    250: "Products",
    251: "The purpose of this website is solely to display information regarding the products and services available on the Crypto.com App. It is not intended to offer access to any of such products and services. You may obtain access to such products and services on the Crypto.com App.",
    252: "Please note that the availability of the products and services on the Crypto.com App is subject to jurisdictional limitations. Crypto.com may not offer certain products, features and/or services on the Crypto.com App in certain jurisdictions due to potential or actual regulatory restrictions.",
    253: "All rights reserved.",
    254: "Privacy Notice",
    255: "Status",
    256: "Cookie Preferences",
    257: "Suggested for you",
    258: "Global",
    259: "All locations",
    260: "United States",
    261: "European Economic Area",
    262: "Singapore",
    263: "Brazil",
    264: "Australia",
    265: "Türkiye",
    266: "France",
    267: "Canada",
    268: "Italy",
    269: "Korea",
    270: "United Kingdom",
    271: "United Arab Emirates",

    272: "Check Crypto Prices",
    273: "Learn about our industry-leading protection",
    274: "Explore our world-class portfolio of partners",
    275: "Read our top stories",
    276: "Track the market's latest developments",
    277: "Our latest updates and launches",
    278: "Connect online and in person",
    279: "Build your crypto knowledge",
    280: "Get our industry-leading insights",
    281: "Everything you need to know about Bitcoin",
    282: "Crypto terms explained",
    283: "Company and product updates",
    284: "Ecosystem",
    285: "About",
    286: "Discover our vision, mission and team",
    287: "Build Web3 with us",

    289: "DeFi Made Simple",
    290: "Your Keys, Your Crypto",
    291: "DeFi Earn",
    292: "No lock-up period and stable returns",
    293: "DeFi Swap",
    294: "Swap DeFi coins and earn Triple Yield",
    295: "Get DeFi Wallet",
    296: "Explore DeFi Features",

    297: "Trade with",
    298: "20+ fiat currencies",
    299: "and Apple/Google Pay",
    300: "Leader in ",
    301: "regulatory compliance",
    302: "and",
    303: "security certifications",
    304: "Trusted by",
    305: "over 100 million users",
    306: "worldwide",

    307: "Trade",
    308: "Ethereum",
    309: "The Crypto.com Korea website and app in the Republic of Korea jurisdiction are provided by Foris DAX Korea Limited.",
    310: "Foris DAX Korea Limited",
    311: "Suite 802, 8F, Jongno 3gil 17, Jongno gu, Seoul, South Korea",
    312: "CEO: Eric Anziani",
    313: "Business Registration Number: 885-88-00694",
    314: "Contact information: 1588-9520",
    315: "Live Chat",

    316: "Download Exchange App",
    317: "Backup restore phrase",
    318: "Your recovery phrase will be displayed next time. Please make sure before continuing",
    319: "I am now in a place of high privacy and can start writing recovery phrases",
    320: "I confirm that I understand that if the recovery phrase is lost, I will lose the wallet and its funds",
    321: "I will not share recovery phrases with anyone, online or offline",
    322: "Show my recovery phrase",
    323: "You will never be asked for your recovery phrase. Keep your recovery phrase securely and do not share it with anyone",
    324: "Continue the achievement",
    325: "Write the following words in numerical order 12 and keep the recovery phrase in a safe place",
    326: "My recovery phrase"
}
