/**
 * Blogs 小猪宰汁
 * Date: 2023/3/2
 * filename:en.js
 */
export const h = {
  1: "시작하기",
  2: "구매 및 판매",
  3: "비트코인, 이더리움 및 350개 이상의 암호화폐 구매",
  4: "당신이 좋아하는 문화를 소유하세요",
  5: "프라임",
  6: "세대의 부를 쌓으세요",
  7: "지출",
  8: "금속 비자 카드",
  9: "모든 지출에 대해 최대 5% 환급",
  10: "Crypto.com 페이",
  11: "어디서나 암호화폐를 사용하세요",
  12: "성장",
  13: "보상",
  14: "모든 거래에 더 많은 보상을 제공하세요",
  15: "암호화폐 획득",
  16: "자산을 최대한 활용하세요",
  17: "온체인 스테이킹",
  18: "소극적 소득 창출",
  19: "DeFi 스테이킹",
  20: "DeFi 프로토콜에 접속하고 보상을 받으세요",
  21: "고급 거래",
  22: "교환",
  23: "홈 교환",
  24: "낮은 수수료와 풍부한 유동성으로 거래하세요",
  25: "마진거래",
  26: "3배의 레버리지를 얻으세요",
  27: "파생상품 거래",
  28: "20배 레버리지, 매우 낮은 대기 시간",
  29: "데파이",
  30: "디파이 지갑",
  31: "하나의 앱, 다양한 DeFi 서비스",
  32: "브라우저 확장",
  33: "데스크톱에서 DeFi 프로토콜에 액세스",
  34: "데스크탑 지갑",
  35: "DeFi 도구를 쉽게 관리하세요",
  36: "생태계",
  37: "비즈니스에 대한 비용 지불",
  38: "암호화폐를 간단하고 안전하게 받아들이세요",
  39: "앱 다운로드",
  40: "스캔하여 다운로드",
  41: "세계의 것",
  42: "프리미어",
  43: "거래 플랫폼",
  44: "비트코인, 이더리움 및 350개 이상의 암호화폐 구매",
  45: "앱 다운로드",
  46: "당신의 암호화폐 여행은 여기서 시작됩니다",
  47: "암호화폐 구매",
  48: "은행 송금을 통해 BTC, ETH 및 기타 암호화폐를 쉽게 구매하세요.",
  49: "BTC, ETH 및 기타 암호화폐를 쉽게 구매하세요",
  50: "함께",
  51: "가격 알림",
  52: "BTC, ETH, XRP 가격 등에 대한 알림을 받으세요.",
  53: "온체인 스테이킹",
  54: "도움을 통해 소극적 소득을 창출하세요",
  55: "블록체인을 보호하기 위해.",
  56: "우리와 함께하세요",
  57: "사용자",
  58: "오늘 시작하세요",
  59: "스캔",
  60: "다운로드하다",
  61: "앱",
  62: "우리와 함께하세요",
  63: "사용자",
  64: "오늘 시작하세요",
  65: "암호화폐 구매",
  66: "은행 송금을 통해 BTC, ETH 및 기타 암호화폐를 쉽게 구매하세요.",
  67: "BTC, ETH 및 기타 암호화폐를 쉽게 구매하세요",
  68: "와",
  69: "가격 알림",
  70: "BTC, ETH, XRP 가격 등에 대한 알림을 받으세요.",
  71: "반복 구매",
  72: "일일, 주간 또는 월간 거래를 통해 자동으로 포트폴리오를 성장시키세요.",
  73: "온체인 스테이킹",
  74: "도움을 통해 소극적 소득을 창출하세요",
  75: "블록체인을 보호하기 위해.",
  76: "우리와 함께하세요",
  77: "사용자",
  78: "오늘 시작하세요",
  79: "죄송합니다. 귀하의 브라우저는 삽입된 비디오를 지원하지 않습니다.",
  80: "CRYPTO.COM 비자 카드",
  81: "당신에게 필요한 유일한 암호화폐 카드",
  82: "매끄럽고 순수한 금속 카드로 모든 지출 금액의 최대 5%를 환급받으세요.",
  83: "연회비가 없습니다. 법정화폐나 암호화폐로 충전하세요.",
  84: "카드를 선택하세요",
  85: "암호화폐 획득",
  86: "안전하게 자산을 최대한 활용하세요",
  87: "비트코인과 스테이블코인을 포함한 21개 이상의 암호화폐 중에서 선택하세요.",
  88: "보상 계산",
  89: "교환",
  90: "세계에서 가장 빠르고 안전한 암호화폐 거래소에서 자신있게 비트코인 ​​및 기타 암호화폐를 거래하세요",
  91: "데스크탑",
  92: "Crypto.com 거래소로 이동",
  93: "모바일",
  94: "교환 앱 다운로드",
  95: "유동성",
  96: "모든 시장 상황에서 높은 주문장 유동성",
  97: "속도",
  98: "6400만 TPS 매칭 엔진",
  99: "370나노초 코어 지연 시간",
  100: "보안",
  101: "싱가포르 데이터 보호 신뢰 마크",
  102: "우리의 비전",
  103: "모든 지갑™의 암호화폐",
  104: "설립지",
  105: "사용자",
  106: "회사 소개",
  108: "가격",
  107: "자주 묻는 질문",
  109: "암호화폐란 무엇인가",
  110: "암호화폐는 라고 불리는 분산 원장 기술을 기반으로 작동하는 디지털 또는 가상 통화입니다",
  111: "블록체인",
  112: "그리고 사용하다",
  113: "암호화",
  114: "보안을 위해. 분산화되어 있으며 중앙은행과 독립적으로 운영됩니다.",
  115: "기존 통화와 달리 암호화폐는 실제 상품이나 정부에 의해 뒷받침되지 않으며 그 가치는 시장 수요와 공급에 따라 결정됩니다. 암호화폐는 상품과 서비스를 구매하고, 자금을 이체하고, 시장에서 거래하는 데 사용할 수 있습니다. 인기 있는 암호화폐는 다음과 같습니다. ",
  116: "비트코인",
  117: "117",
  118: "잔물결",
  119: "그리고",
  120: "비트코인과 같은 많은 암호화폐는 이라는 프로세스를 통해 생성됩니다",
  121: "채굴",
  122: "블록체인에서 거래를 검증하고 기록하기 위해 복잡한 수학 방정식을 푸는 과정이 포함됩니다. 이 메커니즘은 라고도 합니다.",
  123: "작업 증명(PoW)",
  124: ". 에너지 효율성이 높아 인기가 높아진 또 다른 합의 메커니즘은",
  125: "지분 증명(PoS)",
  126: ". PoS는 채굴 대신 거래를 검증하는 네트워크 참여자에게 의존합니다. 두 번째로 큰 암호화폐인 이더리움은 이 합의 메커니즘을 사용합니다.",
  127: "암호화폐 구매처",
  128: "암호화폐를 구매하는 방법에는 다음을 포함하여 여러 가지가 있습니다:",
  129: "중개 서비스:",
  130: "암호화폐 브로커를 사용하면 사용자는 간단하게 암호화폐를 사고 팔 수 있습니다. 인기 있는 예는 다음과 같습니다.",
  131: "1억 명 이상의 사용자가 신뢰하고 있습니다. Apple Store와 Google Play에서 구매하실 수 있습니다.",
  132: "암호화폐 거래소:",
  133: "이것은 사용자가 명목화폐 또는 기타 암호화폐를 사용하여 암호화폐를 구매, 판매 및 거래할 수 있는 온라인 플랫폼입니다. 암호화폐 파생상품과 같은 거래 수단을 추가하여 암호화폐 중개에 비해 더 복잡한 기능을 제공합니다. ",
  134: "교환",
  135: "인기 있는 암호화폐 거래소의 예입니다.",
  136: "P2P(Peer-to-Peer) 마켓플레이스:",
  137: "이것은 구매자와 판매자가 제3자 거래소의 개입 없이 암호화폐를 직접 거래할 수 있는 플랫폼입니다. 이는 라고도 합니다.",
  138: "분산 금융의 줄임말입니다. 다음을 통해 여러 P2P 암호화폐 시장에 모두 하나의 앱으로 액세스할 수 있습니다.",
  139: "디파이 지갑",
  140: "암호화폐를 구매하려면 적절한 조사를 수행하고 평판이 좋은 플랫폼을 선택하는 것이 중요합니다. 예를 들어 Crypto.com은 ",
  141: "업계 최고 보안 등급",
  142: " 또한 Crypto.com 앱이나 Crypto.com DeFi 지갑과 같은 지갑에 암호화폐를 안전하게 보관하는 것이 좋습니다.",
  143: "암호화폐 구매 방법",
  144: "암호화폐를 구매하려면 다음의 일반적인 단계를 따르세요",
  145: "사용할 암호화폐 플랫폼을 선택하세요. ",
  146: "또는",
  147: "'고객 파악'이라고도 알려진 개인 정보 및 ID 확인을 제공하여 선택한 플랫폼에 계정을 생성합니다.",
  148: "절차",
  149: "새로 생성된 계정에 명목화폐나 다른 암호화폐를 입금하세요. Crypto.com 앱은 지역에 따라 암호화폐 구매를 위한 은행 송금, 신용 카드, 직불 카드 및 암호화폐 송금을 지원합니다.",
  150: "Crypto.com 거래소 또는 앱의 '구매' 섹션으로 이동하여 구매할 암호화폐를 선택하세요.",
  151: "구매할 암호화폐 금액을 입력하고 거래를 확인하세요",
  152: "암호화폐는 계정에 입금됩니다. 여기에서 다른 암호화폐 지갑으로 이체하거나 법정화폐로 다시 변환하여 은행 계좌로 지불할 수 있습니다.",
  153: "암호화폐를 구매하려면 적절한 조사를 수행하고 평판이 좋은 플랫폼을 선택하는 것이 중요합니다. 예를 들어 Crypto.com은 ",
  154: "업계 최고 보안 등급",
  155: "또한 암호화폐는 와 같은 지갑에 안전하게 보관하는 것이 좋습니다",
  156: "또는",
  157: "암호화폐 거래 방법",
  158: "암호화폐를 거래하려면 다음의 일반적인 단계를 따르십시오",
  159: "거래를 지원하는 암호화폐 거래소를 선택하세요. 인기 있는 옵션은",
  160: "선택한 플랫폼에 계정을 생성하고 ID 확인을 수행합니다.",
  161: "지원되는 결제 방법을 사용하여 새로 생성된 계정에 자금을 입금하세요. Crypto.com Exchange는 은행 송금 및 신용/직불 카드를 지원합니다.",
  162: "플랫폼의 거래 섹션으로 이동하여 거래할 암호화폐 쌍을 선택하세요",
  163: "암호화폐를 살지 팔지 선택하고, 거래할 금액을 입력하세요",
  164: "선호 가격과 주문 유형을 설정하세요. 시장가 주문, 지정가 주문, 정지 주문, 암호화폐 옵션 등 다양한 주문 유형이 있으며 이를 통해 사용자는 특정 가격이나 특정 조건에서 매수 또는 매도할 수 있습니다.",
  165: "거래 주문을 제출하고 실행될 때까지 기다립니다. 시장 상황에 따라 거래가 즉시 체결될 수도 있고 체결되는 데 시간이 걸릴 수도 있습니다.",
  166: "거래를 모니터링하고 필요에 따라 전략을 조정하세요",
  167: "여기에 소개가 있습니다",
  168: "암호화폐 거래에는 위험이 따릅니다. 손실을 감당할 수 있는 만큼만 거래하는 것이 중요합니다.",
  169: "디파이 지갑",
  170: "('당신의 고객을 알아라')",
  171: "Crypto.com 거래소에서의 거래",
  172: "암호화폐를 얻는 방법",
  173: "암호화폐를 얻을 수 있는 방법은 여러 가지가 있습니다.",
  174: "채굴",
  175: ": 암호화폐 채굴에는 특수 컴퓨터 하드웨어를 사용하여 블록체인 네트워크에서 거래를 검증하는 복잡한 수학 방정식을 푸는 작업이 포함됩니다. 성공적인 채굴자는 그들의 노력에 대해 새로 채굴된 암호화폐로 보상을 받습니다.",
  176: "스테이킹/락업",
  177: "스테이킹 및 락업에는 블록체인 네트워크 운영을 지원하기 위해 지갑이나 플랫폼에 일정량의 암호화폐를 보유하거나 잠그는 것이 포함됩니다. 스테이커는 지원에 대한 관심의 형태로 새로운 암호화폐로 보상을 받습니다.",
  178: "거래",
  179: "암호화폐 거래에는 거래소나 기타 거래 플랫폼에서 암호화폐를 사고 파는 일이 포함됩니다. 시장 동향을 잘 이해하고 정보에 입각한 거래 결정을 내릴 수 있는 사람은 거래를 통해 이익을 얻을 수 있습니다.",
  180: "에어드롭: 에어드롭은 특정 기준을 충족하거나 프로모션 활동에 참여하는 사용자에게 암호화폐를 무료로 배포하는 것입니다.",
  181: "프로젝트",
  182: "일부 블록체인 프로젝트는 개발이나 커뮤니티에 기여한 사용자에게 보상이나 현상금을 제공합니다. 여기에는 버그 현상금, 테스트 또는 콘텐츠 생성과 같은 활동이 포함될 수 있습니다.",
  183: "암호화폐를 얻는 각각의 방법에는 고유한 위험과 보상이 따른다는 점에 유의하는 것이 중요합니다. 암호화폐를 주의 깊게 조사하고 구매하기 전에 프로세스를 이해하는 것이 좋습니다. 암호화폐 시장에 대해 자세히 알아보십시오.",
  184: "암호화폐로 보상을 받는 방법",
  185: "사용자는 보상 계정이나 대출 서비스를 제공하는 다양한 제품을 통해 암호화폐 보유에 대한 보상을 받을 수 있습니다. 암호화폐에 대한 보상을 얻을 수 있는 몇 가지 방법은 다음과 같습니다.",
  186: "그",
  187: "교환",
  188: "그리고",
  189: "디파이 지갑",
  190: "모두 암호화폐에 대한 보상을 얻을 수 있는 다양한 방법을 제공합니다.",
  191: "선택한 플랫폼에 계정을 만들고 암호화폐 보유액을 보상 계정에 입금하세요.",
  192: "플랫폼에 따라 사용자는 자신의 암호화폐를 다른 사용자에게 빌려주거나 일정 기간 동안 암호화폐를 잠그는 방식으로 보상을 받을 수 있습니다.",
  193: "일부 제품은 고정 보상률을 제공합니다(예: ",
  194: "비자 카드",
  195: "다른 사람들은 시장 상황에 따라 가변 금리를 제공할 수 있습니다(예: Crypto.com DeFi 지갑의 '수익 창출' 기능).",
  196: "보상을 모니터링하고 필요에 따라 전략을 조정하세요.",
  197: "암호화폐에 대한 이자와 보상을 얻는 것은 이자율에 영향을 미칠 수 있는 시장 상황의 변동을 포함하여 위험을 수반한다는 점에 유의하는 것이 중요합니다. 암호화폐를 입금하기 전에 이용 약관을 주의 깊게 조사하고 이해하십시오.",
  198: "추가 질문이 있습니다",
  199: "연락처",
  200: "암호화폐 시작하기",
  201: "스캔하여 다운로드",
  202: "기능",
  203: "보상",
  204: "구매 및 판매",
  205: "온체인 스테이킹",
  206: "결제",
  207: "과급기",
  208: "무역 경기장",
  209: "배우다",
  210: "대학",
  211: "연구 및 분석",
  212: "용어집",
  213: "이더리움이란 무엇인가요?",
  214: "블록체인이란 무엇인가요?",
  215: "비트코인을 구매하는 방법은 무엇인가요?",
  216: "이더리움을 구매하는 방법은 무엇인가요?",
  217: "암호화폐를 구매하는 방법은 무엇인가요?",
  218: "암호화폐란 무엇인가요?",
  219: "DeFi란 무엇인가요?",
  220: "가격",
  221: "변환기",
  222: "사이트 위젯",
  223: "최근 트렌드는 무엇인가",
  224: "시장 업데이트",
  225: "제품 뉴스",
  226: "회사 뉴스",
  227: "이벤트",
  228: "회사",
  229: "회사 소개",
  230: "파트너",
  231: "보안",
  232: "예비금 증명",
  233: "기후",
  234: "자본",
  235: "제휴",
  236: "경력",
  237: "목록",
  238: "지원하다",
  239: "기능",
  240: "보상",
  241: "구매 및 판매",
  242: "암호화폐 획득",
  243: "온체인 스테이킹",
  244: "결제하다",
  245: "비즈니스 결제",
  246: "마진거래",
  247: "파생상품 거래",
  248: "과급기",
  249: "무역 경기장",
  250: "제품",
  251: "이 웹사이트의 목적은 오로지 Crypto.com 앱에서 제공되는 제품 및 서비스에 관한 정보를 표시하는 것입니다. 해당 제품 및 서비스에 대한 액세스를 제공하기 위한 것이 아닙니다. 귀하는 해당 제품 및 서비스에 대한 액세스 권한을 얻을 수 있습니다. Crypto.com 앱에서.",
  252: "Crypto.com 앱의 제품 및 서비스 이용 가능 여부는 관할권에 따라 제한됩니다. Crypto.com은 특정 관할권에서 Crypto.com 앱의 특정 제품, 기능 및/또는 서비스를 제공하지 않을 수 있습니다. 잠재적이거나 실제적인 규제 제한 사항입니다.",
  253: "모든 권리는 보유됩니다.",
  254: "개인정보 보호정책",
  255: "상태",
  256: "쿠키 기본 설정",
  257: "당신을 위한 제안",
  258: "전역",
  259: "모든 위치",
  260: "미국",
  261: "유럽 경제 지역",
  262: "싱가포르",
  263: "브라질",
  264: "호주",
  265: "터키예",
  266: "프랑스",
  267: "캐나다",
  268: "이탈리아",
  269: "한국",
  270: "영국",
  271: "아랍에미리트",
  272: "암호화폐 가격 확인",
  273: "업계 최고의 보호 수단 이해",
  274: "세계적인 파트너 그룹 모색",
  275: "우리의 헤드라인 뉴스 읽기",
  276: "시장의 최신 발전 추적",
  277: "최신 업데이트 및 출시",
  278: "온라인과 오프라인 연결",
  279: "당신의 암호화 지식 구축",
  280: "업계 최고의 통찰력 확보",
  281: "비트코인에 대해 알아야 할 모든 것",
  282: "암호화 용어 설명",
  283: "회사 및 제품 업데이트",
  284: "생태계",
  285: "정보",
  286: "우리의 비전, 사명 및 팀 발견",
  287: "우리와 함께 Web3 구축",
  289: "DeFi 단순화",
  290: "당신의 열쇠, 당신의 암호화폐",
  291: "DeFi 돈 벌기",
  292: "자물쇠 없는 주기, 안정적인 보상",
  293: "DeFi 스위칭",
  294: "DeFi 토큰을 교환하고 3배의 수익을 얻는다",
  295: "DeFi 지갑 가져오기",
  296: "DeFi 기능 탐색",
  297: "무역",
  298:"20여 종의 법정 화폐",
  299: "애플/구글 페이와",
  300: "리더",
  301: "규제 준수",
  302: "및",
  303: "안전 인증",
  304: "신뢰",
  305: "1억 명 이상의 사용자",
  306: "전 세계",
  307: "거래",
  308: "이더리움",
  309: "대한민국 관할권의 Crypto.com Korea 웹사이트와 앱은 Foris DAX Korea Limited에서 제공합니다.",
  310: "Foris DAX Korea Limited",
  311: "서울특별시 종로구 종로3길 17, 8층 802호실",
  312: "CEO: Eric Anziani",
  313: "사업자등록번호: 885-88-00694",
  314: "연락처: 1588-9520",
  315: "라이브 채팅",
  316: "교환 앱 다운로드",
  317: "백업 복원 문구",
  318: "다음번에는 복구 문구가 표시됩니다. 계속하기 전에 확인하세요.",
  319: "나는 이제 프라이버시가 높은 곳에 있으므로 복구 문구를 작성할 수 있습니다.",
  320: "복구 문구를 분실하면 지갑과 지갑 자금을 잃게 된다는 점을 이해했음을 확인합니다.",
  321: "나는 온라인이든 오프라인이든 누구와도 복구 문구를 공유하지 않습니다",
  322: "내 복구 문구 표시",
  323: "복구 문구를 묻는 메시지가 표시되지 않습니다. 복구 문구를 안전하게 보관하고 누구와도 공유하지 마세요.",
  324: "업적을 계속하세요",
  325: "다음 단어를 숫자순으로 12번으로 쓰고 복구문구를 안전한 곳에 보관하세요",
  326: "내 복구 문구"
}
