/**
 * Blogs 小猪宰汁
 * Date: 2023/3/2
 * filename:en.js
 */
export const h = {
  1:"開始",
2:"買賣",
3:"購買比特幣、以太幣和 350 多種加密貨幣",
4:"擁有你熱愛的文化",
5:"總理",
6:"累積一代人的財富",
7:"花費",
8:"金屬 Visa 卡",
9:"所有支出高達 5% 的返利",
10:"Crypto.com 支付",
11:"在任何地方使用你的加密貨幣",
12:"成長",
13:"獎勵",
14:"讓每筆交易都更有價值",
15:"加密貨幣賺取",
16:"充分利用你的資產",
17:"鏈上質押",
18:"產生被動收入",
19:"DeFi 質押",
20:"訪問 DeFi 協議並獲得獎勵",
21:"高級交易",
22:"交換",
23:"交換家園",
24:"交易費用低,流動性強",
25:"保證金交易",
26:"獲得 3 倍槓桿",
27:"衍生性商品交易",
28:"20倍槓桿,超低延遲",
29:"DEFI",
30:"DeFi 錢包",
31:"一個應用程序,多種 DeFi 服務",
32:"瀏覽器擴充功能",
33:"在桌面上存取 DeFi 協定",
34:"桌面錢包",
35:"輕鬆管理您的 DeFi 工具",
36:"生態系統",
37:"支付業務費用",
38:"簡單安全地接受加密",
39:"下載應用程式",
40:"掃描下載",
41:"世界的",
42:"總理",
43:"交易平台",
44:"購買比特幣、以太幣和 350 多種加密貨幣",
45:"下載應用程式",
46:"你的加密之旅從這裡開始",
47:"購買加密貨幣",
48:"透過銀行轉帳輕鬆購買 BTC、ETH 和其他加密貨幣。",
49:"輕鬆購買 BTC、ETH 和其他加密貨幣",
50:"與",
51:"價格提醒",
52:"收到有關 BTC、ETH、XRP 價格等的通知。",
53:"鏈上質押",
54:"透過幫助產生被動收入",
55:"保護區塊鏈。",
56:"加入我們的",
57:"用戶",
58:"今天開始",
59:"掃描",
60:"下載",
61:"應用程式",
62:"加入我們的",
63:"用戶",
64:"今天開始",
65:"購買加密貨幣",
66:"透過銀行轉帳輕鬆購買 BTC、ETH 和其他加密貨幣。",
67:"輕鬆購買 BTC、ETH 和其他加密貨幣",
68:"與",
69:"價格提醒",
70:"獲得有關 BTC、ETH、XRP 價格等的通知。",
71:"重複購買",
72:"透過每日、每週或每月的交易自動增加您的投資組合。",
73:"鏈上質押",
74:"透過幫助產生被動收入",
75:"保護區塊鏈。",
76:"加入我們的",
77:"用戶",
78:"今天開始",
79:"抱歉,您的瀏覽器不支援嵌入影片。",
80:"CRYPTO.COM VISA 卡",
81:"你需要的唯一加密卡",
82:"使用時尚的純金屬卡,所有消費均可享受高達 5% 的回饋。",
83:"無年費。用法定貨幣或加密貨幣充值。",
84:"選擇你的卡",
85:"加密貨幣賺取",
86:"安全地充分利用您的資產",
87:"從 21 多種加密貨幣中進行選擇,包括比特幣和穩定幣。",
88:"計算獎勵",
89:"交換",
90:"在世界上最快、最安全的加密貨幣交易所充滿信心地交易比特幣和其他加密貨幣",
91:"桌面",
92:"前往 Crypto.com 交易所",
93:"移動",
94:"下載交換應用程式",
95:"流動性",
96:"所有市場條件下的深度訂單簿流動性",
97:"速度",
98:"6400萬TPS匹配引擎",
99:"370 奈秒核心延遲",
100:"安全",
101:"新加坡資料保護信任標誌",
102:"我們的願景",
103:"每個錢包中的加密貨幣™",
104:"成立於",
105:"用戶",
106:"關於我們",
108:"價格",
107:"常見問題",
109:"什麼是加密貨幣",
110:"加密貨幣是一種數位或虛擬貨幣,在分散式帳本技術上運行,稱為",
111:"區塊鏈",
112:"和使用",
113:"密碼學",
114:"為了安全。它是去中心化的,獨立於中央銀行運作。",
115:"與傳統貨幣不同,加密貨幣不受實體商品或政府支持,其價值由市場需求和供應決定。加密貨幣可用於購買商品和服務、轉移資金以及市場交易。流行的加密貨幣包括",
116:"比特幣",
117:"那裡",
118:"波紋",
119:"和",
120:"許多加密​​貨幣,例如比特幣,是透過稱為」的過程創建的",
121:"採礦",
122:"這涉及解決複雜的數學方程式以驗證和記錄區塊鏈上的交易。這種機制也稱為",
123:"工作證明（PoW）",
124:"。另一種越來越受歡迎的共識機制——因為它更節能——是",
125:"股權證明（PoS）",
126:"。PoS 依賴網路參與者驗證交易,而不是挖礦。第二大加密貨幣以太坊使用此共識機制。",
127:"在哪裡購買加密貨幣",
128:"購買加密貨幣有多種方式,包括：",
129:"經紀服務：",
130:"加密貨幣經紀商允許用戶簡單地買賣加密貨幣。一個流行的例子是",
131:"受到超過 1 億用戶的信賴。可在 Apple Store 和 Google Play 上購買。",
132:"加密貨幣交換：",
133:"這些是在線平台,用戶可以使用法定貨幣或其他加密貨幣購買、出售和交易加密貨幣。與加密經紀業務相比,它們提供更複雜的功能,添加了加密衍生品等交易工具。",
134:"交換",
135:"是流行加密貨幣交易所的一個例子。",
136:"點對點（P2P）市場：",
137:"這些是買家和賣家可以直接交易加密貨幣而無需第三方交易所參與的平台。這也稱為",
138:"去中心化金融的縮寫。可以透過一個應用程式存取多個 P2P 加密貨幣市場",
139:"DeFi 錢包",
140:"進行適當的研究並選擇信譽良好的平台來購買加密貨幣非常重要。例如,Crypto.com 擁有",
141:"業界最高的安全評級",
142:"此外,建議將加密貨幣安全地儲存在 Crypto.com 應用程式或 Crypto.com DeFi 錢包等錢包中。",
143:"如何購買加密貨幣",
144:"要購買加密貨幣,請遵循以下一般步驟",
145:"選擇要使用的加密平台,例如",
146:"或",
147:"透過提供個人資訊和身分驗證在所選平台上建立帳戶,也稱為‘了解您的客戶’",
148:"程序",
149:"將法定貨幣或其他加密貨幣存入新創建的帳戶。Crypto.com 應用程式支援銀行轉帳、信用卡、借記卡和加密貨幣轉帳來購買加密貨幣,具體取決於區域",
150:"導航到 Crypto.com 交易所或應用程式的‘購買’部分,然後選擇要購買的加密貨幣",
151:"輸入要購買的加密貨幣數量並確認交易",
152:"加密貨幣將存入帳戶。從這裡,它可以轉移到其他加密貨幣錢包或轉換回法定貨幣並支付到銀行帳戶",
153:"進行適當的研究並選擇信譽良好的平台來購買加密貨幣非常重要。例如,Crypto.com 擁有",
154:"業界最高的安全評級",
155:"此外,建議將加密貨幣安全地存儲在像這樣的錢包中",
156:"或",
157:"如何交易加密貨幣",
158:"要交易加密貨幣,請遵循以下一般步驟",
159:"選擇支持交易的加密貨幣交易所。一個流行的選項是",
160:"在所選平台上建立帳戶並進行身份驗證,稱為",
161:"使用支援的付款方式將資金存入新建立的帳戶。Crypto.com 交易所支援銀行轉帳和信用卡/金融卡。",
162:"導航到平台的交易部分並選擇要交易的加密貨幣對",
163:"選擇是否買入或賣出加密貨幣,並輸入交易金額",
164:"設定首選價格和訂單類型。訂單類型有多種,包括市價單、限價單、止損單和加密貨幣選擇權,允許用戶以特定價格或在特定條件下買入或賣出",
165:"提交交易指令並等待執行。根據市場情況,交易可能會立即成交,也可能需要一段時間才能成交",
166:"監控交易並根據需要調整策略",
167:"這是介紹",
168:"重要的是要注意交易加密貨幣會帶來風險,並且只交易您可以承受損失的東西",
169:"DeFi 錢包",
170:"（‘了解你的客戶’）",
171:"在 Crypto.com 交易所進行交易",
172:"如何賺取加密貨幣",
173:"有多種方法可以賺取加密貨幣,包括",
174:"採礦",
175:"：加密貨幣挖礦涉及使用專門的電腦硬體來解決複雜的數學方程,以驗證區塊鏈網路上的交易。成功的礦工將因其努力而獲得新鑄造的加密貨幣的獎勵。",
176:"質押/鎖定",
177:"質押和鎖定涉及在錢包或平台上持有或鎖定一定數量的加密貨幣,以支持區塊鏈網絡的運作。質押者將獲得新的加密貨幣作為他們支持的利息形式的獎勵。",
178:"交易",
179:"加密貨幣交易涉及在交易所或其他交易平台買賣加密貨幣。那些對市場趨勢有充分了解並能夠做出明智交易決策的人可以透過交易賺取利潤。",
180:"空投：空投是向符合一定條件或參與促銷活動的用戶免費分發加密貨幣。",
181:"項目",
182:"一些區塊鏈項目為為其開發或社區做出貢獻的用戶提供獎勵或賞金。這可能包括錯誤賞金、測試或內容創建等活動。",
183:"需要注意的是,每種賺取加密貨幣的方法都有其自身的風險和回報。建議在購買之前仔細研究加密貨幣並了解其流程。了解有關加密貨幣市場的更多資訊",
184:"如何透過加密貨幣賺取獎勵",
185:"用戶可以透過提供獎勵帳戶或借貸服務的各種產品,從其持有的加密貨幣中獲得獎勵。以下是一些賺取加密貨幣獎勵的方法：",
186:"這個",
187:"交換",
188:"和",
189:"DeFi 錢包",
190:"都提供了不同的方式來賺取加密貨幣獎勵,稱為",
191:"在所選平台上建立一個帳戶,並將持有的加密貨幣存入獎勵帳戶。",
192:"根據平台的不同,用戶可以透過將加密貨幣借給其他用戶或鎖定加密貨幣一段時間來獲得獎勵。",
193:"有些產品提供固定獎勵率（例如,",
194:"維薩卡",
195:"而其他人可能會根據市場條件提供可變利率（例如 Crypto.com DeFi 錢包中的『賺取』功能）。",
196:"監控獎勵並根據需要調整策略。",
197:"需要注意的是,透過加密貨幣賺取利息和獎勵會帶來風險,包括可能影響利率的市場狀況波動。在存入加密貨幣之前,請務必仔細研究和理解條款和條件。",
198:"還有更多問題嗎",
199:"聯絡我們",
200:"開始使用加密貨幣",
201:"掃描下載",
202:"特徵",
203:"獎勵",
204:"買賣",
205:"鏈上質押",
206:"支付",
207:"增壓器",
208:"交易競技場",
209:"學習",
210:"大學",
211:"研究與分析",
212:"術語表",
213:"什麼是以太坊？",
214:"什麼是區塊鏈？",
215:"如何購買比特幣？",
216:"如何購買以太坊？",
217:"如何購買加密貨幣？",
218:"什麼是加密貨幣？",
219:"什麼是 DeFi？",
220:"價格",
221:"轉換器",
222:"網站小工具",
223:"流行趨勢是什麼",
224:"市場更新",
225:"產品新聞",
226:"公司新聞",
227:"事件",
228:"公司",
229:"關於我們",
230:"夥伴",
231:"安全",
232:"儲備證明",
233:"氣候",
234:"資本",
235:"附屬機構",
236:"事業",
237:"列表",
238:"支持",
239:"特徵",
240:"獎勵",
241:"買賣",
242:"加密貨幣賺取",
243:"鏈上質押",
244:"支付",
245:"支付業務費用",
246:"保證金交易",
247:"衍生性商品交易",
248:"增壓器",
249:"交易競技場",
250:"產品",
251:"本網站的目的僅是顯示有關 Crypto.com 應用程式上提供的產品和服務的資訊。無意提供對任何此類產品和服務的存取權限。您可以獲得對此類產品和服務的存取權限在Crypto.com 應用程式上。",
252:"請注意,Crypto.com 應用程式上的產品和服務的可用性受到司法管轄區的限制。由於以下原因,Crypto.com 可能無法在某些司法管轄區的Crypto.com 應用程式上提供某些產品、功能和/或服務：潛在或實際的監管限制。",
253:"保留所有權利。",
254:"隱私權聲明",
255:"狀態",
256:"Cookie 偏好設定",
257:"為您推薦",
258:"全球",
259:"所有地點",
260:"美國",
261:"歐洲經濟區",
262:"新加坡",
263:"巴西",
264:"澳大利亞",
265:"土耳其",
266:"法國",
267:"加拿大",
268:"義大利",
269:"韓國",
270:"英國",
271:"阿拉伯联合酋长国",
  272:"檢查加密貨幣價格",
273:"瞭解我們行業領先的保護措施",
274:"探索我們世界級的合作夥伴組合",
275:"閱讀我們的頭條新聞",
276:"跟踪市場的最新發展",
277:"我們的最新更新和發佈",
278:"線上和線下連接",
279:"建立你的加密知識",
280:"獲取我們行業領先的見解",
281:"關於比特幣你需要知道的一切",
282:"加密術語解釋",
283:"公司和產品更新",
284:"生態系統",
285:"關於",
286:"發現我們的願景、使命和團隊",
287:"與我們一起構建Web3",
  289:"DeFi變得簡單",

290:"你的鑰匙，你的加密貨幣",

291:"DeFi賺錢",

292:"無鎖定期，回報穩定",

293:"DeFi交換",

294:"交換DeFi代幣並獲得三倍收益",

295:"獲取DeFi錢包",

296:"探索DeFi功能",
  297:"貿易",
298:"20多種法定貨幣",
299:"和蘋果/穀歌支付",
300:"領導者",
301:"監管合規",
302:"和",
303:"安全認證",
304:"信任",
305:"超過1億用戶",
306:"全球",
  307: "貿易",

 308:"以太坊",
 309:"韓國管轄範圍內的 Crypto.com 韓國網站和應用程式由 Foris DAX 韓國有限公司提供。」",
310:"Foris DAX 韓國有限公司",
 311:"韓國首爾鐘路區鐘路 3 街 17 號 8 樓 802 室",
 312:"首席執行官：埃里克·安齊亞尼",
 313:"商業登記號碼：885-88-00694",
 314:"聯絡方式：1588-9520",
 315:"即時聊天",

    316: "下載交換應用程式",
    317: "備份復原短語",
    318: "您的復原短語會在下一真顯示。繼續操作前，請先 確保",
    319: "我現在處在隱密度高的地方，可以开始寫下復原短語",
    320: "我確認已了解如果復原短語遺失，即等於失去錢包及其資金",
    321: "我不會以線上或離線方式，與任何人分享復原短語",
    322: "顯示我的復原短語",
    323: "絕不會要求您提供復原短語。妥善存放復原短語，亦不要與任何人分享",
    324: "繼績",
    325: "按數字順序 12 寫下以下單詞，並妥善保管復原短語",
    326: "我的復原短語"
}
