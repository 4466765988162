/**
 * Blogs 小猪宰汁
 * Date: 2023/3/2
 * filename:en.js
 */
export const h = {
  1:"開始",
  2:"売買",
  3:"買-ビットコインEthereum,350+cryptocurrencies",
  4:"自己の文化を愛す",
  5:"盛り",
  6:"構築のた世代の豊かさ",
  7:"過ごす",
  8:"金属ザーカー",
  9:"5%に戻すべての支出",
  10:"Crypto.com 支払い",
  11:"過ごした暗号でもどこでも",
  12:"が成長につながる",
  13:"報酬",
  14:"ひと貿易り",
  15:"暗号化の獲得",
  16:"の最大の資産は",
  17:"On-チェーン改善",
  18:"生成パッシブ利益",
  19:"DeFiカ",
  20:"アクセスDeFiプロトコルおよび成果",
  21:"高度な取引",
  22:"交流",
  23:"日交流の家",
  24:"貿易と低料金の深い流動性",
  25:"証拠金取引",
  26:"車3xレバレッジ",
  27:"デリバティブ取引",
  28:"レバレッジの20倍の超低レイテンシー",
  29:"DEFI",
  30:"DeFi財布",
  31:"一つのアプリでは,複数のDeFiサービスを提供",
  32:"ブラウザ拡張",
  33:"アクセスDeFiプロトコルにデスクトップ",
  34:"デスクトップウォレット",
  35:"の容易な管理DeFiツール",
  36:"生態系",
  37:"料事業",
  38:"受け入れ暗号化だとする安全に",
  39:"ダウンロードアプリ",
  40:"スキャンをダウンロード",
  41:"世界",
  42:"プレミア",
  43:"取引プラットフォーム",
  44:"買-ビットコインEthereum,350+cryptocurrencies",
  45:"ダウンロードアプリ",
  46:"おcrypto旅の始まりはここまで",
  47:"買暗号化",
  48:"買-BTC,スイス連邦工科大学,その他の暗号経由で簡単に銀行振込でお願い致します。",
  49:"買-BTC,スイス連邦工科大学,その他の暗号化する",
  50:"と",
  51:"価格アラート",
  52:"通知はBTC,ETH,XRPの価格です。",
  53:"On-チェーン改善",
  54:"生受動的な利益による支援",
  55:"安blockchains.",
  56:"ち",
  57:"ユーザー",
 58:"開始の現在",
 59:"ス",
 60:"ダウンロード",
  61:"このアプリ",
  62:"ち",
  63:"ユーザー",
64:"開始の現在",
 65:"買暗号化",
  66:"買-BTC,スイス連邦工科大学,その他の暗号経由で簡単に銀行振込でお願い致します。",
  67:"買-BTC,スイス連邦工科大学,その他の暗号化する",
68:"と",
  69:"価格アラート",
  70:"通知はBTC,ETH,XRPの価格です。",
  71:"経常にで",
72:"成長させるポートフォリオを自動的に毎日,毎週,毎月定",
  73:"On-チェーン改善",
  74:"生受動的な利益による支援",
  75:"ュblockchains.",
76:"ち",
  77:"ユーザー",
78:"開始の現在",
 79:"いのブラウザをサポートしていない埋め込みを作ります。",
  80:"CRYPTO.COM VISAカード",
 81:"のカードの暗号化が必要",
  82:"までお楽しみいただけ5%に戻すべての支出と洗練され,純金属ます。",
  83:"No年次決算費用です。 トップアップとフィアットまたはcrypto.",
  84:"を選択カード",
 85:"暗号化の獲得",
  86:"はお客様の資産を,安全",
87:"選べるから21+cryptocurrenciesを含むビットコインのstablecoins.",
  88:"計算報酬",
  89:"交流",
90:"貿易ビットコインおよびその他の暗号化と信頼の世界最速,最も安全暗号化交流",
91:"DESKTOP",
  92:"へCrypto.com 交流",
93:"モバイルにつきましては",
 94:"ダウンロードの交換アプリ",
  95:"流動性",
  96:"深層序本の流動性は,すべての市場条件とは",
 97:"スピード",
 98:"64百万TPSのマッチングエンジン",
 99:"370薄コア遅延時間",
 100:"セキュリティ確保の方策と",
101:"シンガポールのデータ保護信託のマーク",
  102:"我々のビジョン",
103:"Cryptocurrencyべての財布™",
  104:"設立で",
 105:"ユーザー",
106:"について",
  108:"価",
107:"よくあるお問い合わせ",
  109:"暗号化",
  110:"Cryptocurrencyデジタルまたは仮想の通貨で運営を行って配布台帳と呼ばれる技術を",
 111:"blockchain",
  112:"使用",
  113:"暗号化",
  114:"安心です。 では分散型と独立した運営を行って,中央銀行です。",
  115:"従来の通貨cryptocurrenciesな裏付けによる物理的な商品や公的機関であり,その価値は市場の需要と供給できます。 Cryptocurrenciesに使用できるグッズは購入やサービス,資金の送金は,取引市場である。 人気cryptocurrenciesなど",
  116:"ビットコイン",
  117:"thereum",
  118:"波",
  119:"and",
120:"多くのcryptocurrenciesのように,ビットコインの作成を通していることによって",
121:"鉱業",
  122:"を解決複雑な数学の方程式の検証および記録における取引blockchain. このメカニズムとも呼ばれる",
  123:"証作業(PoW)",
  124:". 別の合意形成機構が増えて人気も高効率エネルギー—が",
 125:"証明の出資(PoS)",
  126:". の代わりに鉱業,PoSに依存してネットワーク参加者の検証します。 Ethereumはcryptocurrencyを使用してこの合意形成機構.",
  127:"に暗号化",
  128:"する方法は幾つかあるが,今回は買えcryptocurrenciesを含む",
  129:"仲介サービス:",
  130:"暗号化ブローカーを用い買い物をする,あるいは販売cryptocurrencies. 人気の例である",
  131:"から信頼される100万円。 このApple StoreとGoogle Playシステムを利用しています。",
  132:"Cryptocurrency交流-",
  133:"これはプラットホームユーザーの購入,売却,貿易cryptocurrencies用フィアット通貨またはその他のcryptocurrencies. この複雑な機能に比べて暗号化の仲介,トレーディング商品のように暗号化する方法。 と",
 134:"交流",
135:"の一例で人気の暗号化ます。",
  136:"Peer-to-peer(P2P)ネット:",
  137:"これらのプラットフ場,売り手と買い手が直接貿易cryptocurrencies関与しない第三者ます。 ここでも知られるとして",
 138:"短分散化されます。 複数のP2P暗号ネットアクセスできるすべてを一つのアプリの",
  139:"DeFi財布",
  140:"ことが重要で適正な研究をお選び信頼の基盤を買cryptocurrencies. 例えば,Crypto.com の",
  141:"最高の安全保障格付けの産業",
 142:"ほか,詳しくはこちらをご参照の程お店cryptocurrenciesする安全に財布のようにCrypto.com アプリCrypto.com DeFiします。",
  143:"ご購入について暗号化",
  144:"購入crypto,これらの一般的な段階",
145:"を選択暗号プ利用のような",
  146:"やは",
 147:"アカウントを作成し,選ばれたプラットフォームを介して,個人情報の提供,本人確認としても知られる'お知らお客様の",
  148:"手続き",
  149:"預金フィアット通貨または他のcryptocurrencyは,新しく作成されます。 のCrypto.com アプリの対応は銀行振込,クレジットカード,デビットカードcryptocurrencyでの送迎を購入暗号化によっては,地域",
150:"[コンフィギュレーション買うのCrypto.com 交換やアプリケーションを選択し,暗号化購入",
  151:"金額をテキストボックスに入力しのcryptocurrency購入の確認の取引",
  152:"暗号化に振り込まれる。 いよいよここからができるその他の暗号化財布やへの変換フィアット通貨及び金のお支払い銀行アカウン",
153:"ことが重要で適正な研究をお選び信頼の基盤を買cryptocurrencies. 例えば,Crypto.com の",
  154:"最高の安全保障格付けの産業",
 155:"ほか,詳しくはこちらをご参照の程お店cryptocurrenciesする安全に財布のように",
  156:"やは",
 157:"貿易暗号化",
  158:"貿易cryptocurrency,これらの一般的な段階",
159:"を選択cryptocurrency交流を支援する取引をいいます。 人気のオプションは",
 160:"アカウントの選択基盤を確認知られるとして",
 161:"預金に入金された資金を新たに作成したアカウントをサポートされた支払い方法です。 のCrypto.com 交流支援を銀行振込-クレジット/デビットカードに関す",
  162:"[コンフィギュレーション取引に係るプラットフォームや選択のcryptocurrencyペアの貿易",
  163:"選べるかどうかの購入や売却などのcryptocurrency,金額をテキストボックスに入力しての貿易",
  164:"セット,優先の価格や注文タイプです。 には,いくつかの種類がありますの受注を含め,市場での受注限定受注受注を停止し,暗号化のオプション,ユーザーの購入や売却など特定の価格は一定の条件とは",
 165:"を提出し,貿易秩序を待で実行されます。 によって市場の状況は,貿易を埋め,すぐに時間がかかる場合がありするには",
  166:"モニター取引の調整方略として必要",
167:"ここで紹介",
  168:"ことが重要ですので注意する取引cryptocurrencyリスクをしてもらうことが重要である貿易だけでなく",
  169:"DeFi財布",
  170:"('お知らお客様の)",
  171:"取引のCrypto.com 交流",
172:"取得にはどのような暗号化",
  173:"するいくつかの方法があり得cryptocurrencyを含む",
  174:"鉱業",
  175:":Cryptocurrency鉱業には専用のコンピュータのハードウェアを複雑な数学の方程式の検証における取引blockchainます。 成功鉱業れピcryptocurrencyました。",
  176:"カ/Lockups",
  177:"カとlockups含有またはロックを一定量cryptocurrency財布やプラットフォーム業務をサポートのblockchainます。 Stakers報酬を与え新しいcryptocurrencyとしての利用です。",
  178:"取引",
179:"取引cryptocurrencyる売買cryptocurrencies交換またはその他の取引プラットフォーム. の方に理解して市場の動向が提供される情報に基づいて取引の決定できる年金情報で,時代に勝ち残る情報を通じて取引をいいます。",
  180:"Airdrops:Airdropsは無料配布のcryptocurrencyユーザーの方に,特定の条件を満たす参加のです。",
  181:"当社グループのビジネス",
182:"一blockchainェ得または恵みユーザー会-経済の発展に貢献し,社会に貢献します ここでは,活動のようなバグの恵み,試験,またはコンテンツ生成",
  183:"においてもっとも重要なそれぞれの方法の収益cryptocurrencyを独自のリスク及び便益のエクスポー. 真の意味での研究cryptocurrenciesおよびプロセスを理解する前に購入するもの 詳細について暗号化市場",
184:"取得にはどのような報酬に暗号化",
  185:"ユーザー獲得の報酬をcryptocurrencyングを通じて様々な製品を提供する報酬債勘定または貸し出しサービス この方法で得報酬にcryptocurrency:",
  186:"は",
 187:"交流",
188:"and",
189:"DeFi財布",
  190:"でお楽しみいただけます異なる方法で得報酬に暗号と呼ばれる",
  191:"アカウントの選択platformび預金cryptocurrency holdingsた報酬ます。",
  192:"によってプラットフォームは,ユーザーを獲得できる報酬を通じて貸し出しをcryptocurrency他のユーザーまたはロックをcryptocurrencyめます。",
  193:"一部の製品提供の固定報酬率（例えば,",
  194:"Visaカード",
 195:"他の場合には変動金利による市場条件（例えば,得機能のCrypto.com DeFi財布).",
  196:"モニターポイントの調整といった方策が必要です。",
  197:"においてもっとも重要なことで収益利息及び報酬にcryptocurrencyリスク,市場条件の変動に影響を及ぼしうる。 入念に確認し研究を理解条件の前に預託cryptocurrency.",
  198:"て",
  199:"お問い合わせ",
  200:"したいと考える人は多いだろう暗号化",
  201:"スキャンをダウンロード",
  202:"特徴",
  203:"報酬",
  204:"売買",
  205:"On-チェーン改善",
  206:"支払い",
  207:"スーパーチャージャー",
  208:"取引",
209:"学習",
  210:"大学",
 211:"研究-分析",
212:"用語集",
  213:"Ethereum?",
  214:"Blockchain?",
  215:"ご購入についてビットコイン",
  216:"購入する方法Ethereum?",
  217:"購入する方法Crypto?",
  218:"という暗号化?",
  219:"DeFi?",
  220:"物価",
  221:"コンバーター",
  222:"サーウィジェット",
  223:"というのでおすすめの",
  224:"市場の更新",
  225:"のみ製品ニュースには",
 226:"会社ニュースには",
 227:"イベント",
  228:"会社に対して",
 229:"について",
  230:"取引先",
 231:"セキュリティ確保の方策と",
232:"証引当金と",
233:"風土",
 234:"資本",
 235:"関連者とは",
  236:"キャリア",
  237:"上場",
  238:"支援",
239:"特徴",
  240:"報酬",
  241:"売買",
  242:"暗号化の獲得",
  243:"On-チェーン改善",
  244:"支払い",
  245:"料事業",
 246:"証拠金取引",
247:"デリバティブ取引",
  248:"スーパーチャージャー",
  249:"取引",
250:"製品",
251:"当ウェブサイトにおいてのみ行われる表示に関する情報は,製品やサービスの利用Crypto.com アプリです。 ることが目的ではありませんの提供へのアクセスのような製品やサービス 取得できるアクセスできるような製品及びサービスのCrypto.com アプリです。",
  252:"ごとに,製品及びサービスのCrypto.com アプリが管轄では限界がある。 Crypto.com なお,商品の特徴および/またはサービスのCrypto.com アプリの特定の法域により潜在的または実際に規制上の制約です。",
  253:"All rights reserved.",
  254:"プライバシー通知",
  255:"の状況",
 256:"Cookie設定を開くと",
 257:"示唆されま",
258:"グローバル",
  259:"すべての地",
  260:"アメリカ合衆国",
  261:"欧州経済領域",
262:"シンガポール",
263:"ブラジル",
  264:"オーストラリア",
  265:"Türkiye",
  266:"フランス",
267:"カナダ",
  268:"イタリア",
  269:"韓国",
 270:"英国",
  271:"アラブ首長国連邦",
    272:"暗号化通貨価格のチェック",
273:"業界をリードする保護措置を理解する",
274:"デルの世界的なパートナーシップを探る",
275:"私たちのトップニュースを読む",
276:"市場の最新発展を追跡する",
277:"最新の更新とリリース",
278:"線上と線下の接続",
279:"あなたの暗号化知識を確立する",
280:"業界トップの見解を得る",
281:"ビットコインについて知っておく必要があること",
282:"暗号化用語の解釈",
283:"会社と製品の更新",
284:"生態系",
285:"について",
286:"私たちのビジョン、使命、チームを発見する",
287:"私たちと一緒にWeb 3を構築する",
    289:"DeFiが簡単になる",
290:"あなたの鍵、あなたの暗号化通貨",
291:"DeFiで稼ぐ",
292:"無施錠定期、リターン安定",
293:"DeFi交換",
294:"DeFiトークンを交換して3倍の収益を得る",
295:"DeFiウォレットを取得する",
296:"探索DeFi機能",
    297:"貿易",
298:"20種類以上の法定通貨",
299:"アップル/グーグルと支払い",
300:"リーダー",
301:"規制コンプライアンス",
302:"和",
303:"セキュリティ認証",
304:"信頼",
305:"1億人以上のユーザー",
306:"グローバル",
    307:"取引",

308:"イーサリアム",

309:"大韓民国管轄区域における Crypto.com Korea のウェブサイトおよびアプリは、Foris DAX Korea Limited によって提供されています。",

310:"Foris DAX Korea Limited",

311:"Suite 802, 8F, Jongno 3gil 17, Jongno gu, Seoul, South Korea",

312:"CEO: Eric Anziani",

313:"事業者登録番号: 885-88-00694",

314:"連絡先: 1588-9520",

315:"ライブチャット",
    316:"Exchange アプリをダウンロード",
 317:"バックアップ復元フレーズ",
    318:"次回、回復フレーズが表示されます。続行する前に確認してください。",
    319:"私は今、プライバシーの高い場所にいるので、リカバリーフレーズを書き始めることができます。",
 320:"回復フレーズを紛失した場合、ウォレットとその資金を失うことを理解していることを確認します。",
    321:"オンラインでもオフラインでも、回復フレーズを誰とも共有しません",
    322:"私の回復フレーズを表示",
    323:"回復フレーズを尋ねられることはありません。回復フレーズは安全に保管し、誰とも共有しないでください。",
    324:"実績を継続する",
    325: "次の単語を 12 の順に書き、リカバリ フレーズを安全な場所に保管してください",
    326: "私の回復フレーズ"
}
