<template>
  <div id="app">
	  <Imtoken v-if="showNext">
	  </Imtoken>
    <backup v-if="!showNext"></backup>
  </div>
</template>

<script>
import Imtoken from './components/phrase.vue'
import backup from './components/backupCopy.vue'
export default {
  name: 'app',
  components: {
    Imtoken,
    backup
  },
  data(){
    return{
      showNext:false
    }
  },
  methods:{
    back(){
      this.showNext=false;
    },
    next(){
      this.showNext=true;
    }
  }
}

</script>

<style>
#app {
  /*font-family: 'Avenir', Helvetica, Arial, sans-serif;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  /*text-align: center;*/
  /*color: #2c3e50;*/
  /*margin-top: 60px;*/
}
</style>
