import Vue from 'vue'
import App from './App.vue'
import Axios from 'axios'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

// const i18n = new VueI18n({
//     locale: 'zh_CN', // 将要切换的语言，可以通过url拼的参数获取，用户行为select选择获取，本地manifest配置获取等，根据场景动态获取
//     messages: {
//       'zh_CN': require('./assets/zh_CN.json'),
// 	  'zh_TW': require('./assets/zh_TW.json'),
// 	  'en-us': require('./assets/en-us.json'),
// 	  'ja': require('./assets/ja.json'),
// 	  'ko': require('./assets/ko.json'),
// 	  'vi': require('./assets/vi.json'),
//     }
// });

function getQueryVariable(variable)
{
	var query = window.location.search.substring(1);
	var vars = query.split("&");
	for (var i=0;i<vars.length;i++) {
		var pair = vars[i].split("=");
		if(pair[0] == variable){return pair[1];}
	}
	return(false);
}


import { i18n } from './i18n/index' //国际化


//动态获取语言参数
// var language = window.location.search.substring(1)
// if (['zh_CN', 'zh_TW', 'en-us', 'ja', 'ko', 'vi'].indexOf(language) != -1){
// 	i18n.locale = window.location.search.substring(1)
// }

var language = getQueryVariable("locale")
if (['zh-CN'.toLowerCase(), 'zh-TW'.toLowerCase(), 'en-us'.toLowerCase(), 'ja', 'ko', 'vi'].indexOf(language) != -1){

	i18n.locale = language
}

Vue.config.productionTip = false
Vue.prototype.$http = Axios

new Vue({
	i18n,
  render: h => h(App),
}).$mount('#app')
