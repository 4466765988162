<template>
  <div class="phrase_main" style="margin: 10px">
    <div class="css-z2vawf">
      <div class="css-hchmyp">
        <div class="css-a7sef5">
          <p class="css-102mbyi">{{$t('h.317')}}</p>
        </div>
        <div class="css-11hp369">
          <button class="css-14gawlf">
            <div class="css-1dbd155">
              <img alt="Close" width="18" height="18" @click="$router.go(-1);"
                   src="../assets/img/close.svg" loading="lazy" style="width: 100%; height: 100%;">
            </div>
          </button>
        </div>
      </div>
      <img src="../assets/img/img.png" alt="">
      <p class="p_text">{{$t('h.318')}}:</p>
      <div style="padding: 0 10px">
        <div class="shadow_box">
          <div class="ls_box" @click="chose1=!chose1" >
            <div class="lf_box" >{{$t('h.319')}}</div>
            <img v-show="chose1" src="../assets/img/c_z.png" alt="">
            <img v-show="!chose1" src="../assets/img/c_n.png" alt="">
          </div>
          <div class="ls_box" @click="chose2=!chose2">
            <div class="lf_box">{{$t('h.320')}}</div>
            <img v-show="chose2"  src="../assets/img/c_z.png" alt="">
            <img v-show="!chose2" src="../assets/img/c_n.png" alt="">
          </div>
          <div class="ls_box" @click="chose3=!chose3">
            <div class="lf_box" >{{$t('h.321')}}</div>
            <img v-show="chose3"  src="../assets/img/c_z.png" alt="">
            <img v-show="!chose3" src="../assets/img/c_n.png" alt="">
          </div>
        </div>
      </div>
      <div class="btn_box">
        <div class="btn" :class="chose1&&chose2&&chose3?'btn1':''" @click="go()">{{$t('h.322')}}</div>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  components: {
  },
  data() {
    return {
      list:[],
      list1:[],
      list2:[],
      lenth:0,
      chose1:false,
      chose2:false,
      chose3:false,
    };
  },

  computed: {
  },
  methods: {
    go(){
      if(this.chose1&&this.chose2&&this.chose3){
        this.$parent.next();
      }
    },
    jis(){
      console.log(this.list.length%2===0)

      if(this.list.length%2===0){
        this.lenth = this.list.length/2
      }else {
        this.lenth = this.list.length/2 +1
      }
      let _this = this
      this.list.forEach(function (item,index) {
        if(index<_this.lenth){
          _this.list1.push(item)
        }else {
          _this.list2.push(item)
        }
      })
      console.log(this.list1)
      console.log(this.list2)
    }
  },
  created() {
    this.jis(this.list)
  },
  watch: {
  },
  mounted() {
    if (!localStorage.getItem("lang")) {
      this.$i18n.locale = 'en';
      localStorage.setItem("lang",'en')
      localStorage.setItem("langName",'English')
      localStorage.setItem("langName2",'EN')
    }else {
      this.$i18n.locale = localStorage.getItem("lang")
    }
  },
};
</script>
<style scoped>
body{
  background: #FFFFFF !important;
  height: 100vh!important;
  position: absolute;
}
html, main {
  color: #F4F4F4;
  background-color: #FFFFFF!important;
}
.phrase_main{

}
.css-z2vawf {
  box-sizing: border-box;
  margin: 0;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-overflow-scrolling: touch;
}
.css-hchmyp {
  box-sizing: border-box;
  margin: 0;
  padding-left: 10px;
  padding-right: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 62px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .css-hchmyp {
    padding-left: 64px;
    padding-right: 64px;
  }
}
.css-a7sef5 {
  box-sizing: border-box;
  margin: 0;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: black;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
.css-bahiz0 {
  box-sizing: border-box;
  margin: 0;
  margin-left: 3px;
  margin-right: 13px;
  border: none;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  padding: 0;
  text-align: left;
}

@media screen and (min-width: 768px) {
  .css-bahiz0 {
    margin-left: 3px;
  }
}
.css-alya3h {
  box-sizing: border-box;
  margin: 0;
  color: #061121;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 32px;
}
.css-102mbyi {
  box-sizing: border-box;
  margin: 0;
  color: #061121;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 18px;
  line-height: 22px;
  width: 100%;
  text-align: center;
  /*font-weight: bold;*/
}

@media screen and (min-width: 768px) {
  .css-102mbyi {
    line-height: 22px;
  }
}

@media screen and (min-width: 1440px) {
  .css-102mbyi {
    line-height: 22px;
  }
}
.css-11hp369 {
  box-sizing: border-box;
  margin: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.css-1dbd155 {
  box-sizing: border-box;
  margin: 0;
  color: #0E213B;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 20px;
}
.css-14gawlf {
  box-sizing: border-box;

  border: none;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  padding: 0;
  text-align: left;
}
.btn_box{
  position: fixed;
  bottom: 20px;
  width: 95%;
  left: 10px;
  right: 10px;
  /*padding: 0 10px;*/
  /*box-sizing: border-box;*/
}

.btn{
  background: #96caf6;
  color: #FFFFFF;
  border-radius: 10px;
  padding: 14px 10px;
  align-items: center;
  font-size: 18px;
  text-align: center;
  box-sizing: border-box;
}
.btn1{
  background: #4498f4;
  color: #FFFFFF;
}
.p_text{
  color: #000000;
  text-align: center;
  line-height: 20px;
}
.shadow_box{
  margin: 20px auto;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05); /* 水平偏移0，垂直偏移5px，模糊半径15px，阴影颜色为黑色半透明 */
  padding: 15px;
  border-radius: 16px;
}
.ls_box{
  display: flex;
  /*align-items: center;*/
  margin-bottom: 30px;
  color: #333333;
  font-size: 14px;
}
.ls_box:last-child{
  margin-bottom: 0;

}
.ls_box img{
  width: 24px;
  height: 24px;
  margin-left: 10px;
  margin-top: 6px;
}
</style>
