/**
 * Blogs 小猪宰汁
 * Date: 2023/3/2
 * filename:en.js
 */
export const h = {
  1: "Comenzar",
  2: "COMPRAR Y VENDER",
  3: "Compre Bitcoin, Ethereum y más de 350 criptomonedas",
  4: "Sé dueño de la cultura que amas",
  5: "principal",
  6: "Construye tu riqueza generacional",
  7: "GASTAR",
  8: "Tarjetas Visa metálicas",
  9: "Hasta un 5% de reembolso sobre todos los gastos",
  10: "Pago Crypto.com",
  11: "Gasta tus criptomonedas en cualquier lugar",
  12: "CRECER",
  13: "Recompensas",
  14: "Haga que cada operación sea más gratificante",
  15: "Ganancia criptográfica",
  16: "Saca el máximo partido a tu patrimonio",
17: "Apuestas en cadena",
  18: "Generar ingresos pasivos",
  19: "Apuesta DeFi",
  20: "Acceda a los protocolos DeFi y obtenga recompensas",
  21: "Comercio avanzado",
  22: "INTERCAMBIO",
  23: "Cambio de casa",
  24: "Opere con tarifas bajas y gran liquidez",
  25: "Negociación de márgenes",
  26: "Obtenga un apalancamiento 3x",
  27: "Negociación de derivados",
  28: "Apalancamiento 20x, latencia ultrabaja",
  29: "DEFI",
  30: "Monedero DeFi",
  31: "Una aplicación, múltiples servicios DeFi",
  32: "Extensión del navegador",
  33: "Acceder a los protocolos DeFi en el escritorio",
  34: "Monedero de escritorio",
  35: "Administra fácilmente tus herramientas DeFi",
  36: "ECOSISTEMA",
  37: "Pagar por negocios",
  38: "Acepte criptomonedas de forma sencilla y segura",
  39: "Descargar aplicación",
  40: "Escanear para descargar",
  41: "El mundo",
  42: "Primer Ministro",
  43: "Plataforma comercial",
  44: "Compre Bitcoin, Ethereum y más de 350 criptomonedas",
  45: "Descargar aplicación",
  46: "Tu viaje criptográfico comienza aquí",
  47: "Comprar criptomonedas",
  48: "Compre BTC, ETH y otras criptomonedas fácilmente mediante transferencia bancaria.",
  49: "Compre BTC, ETH y otras criptomonedas fácilmente",
  50: "con",
  51: "Alertas de precios",
  52: "Reciba notificaciones sobre los precios de BTC, ETH, XRP y más.",
  53: "Apuestas en cadena",
  54: "Genera ingresos pasivos ayudando",
  55: "para asegurar blockchains.",
  56: "Únete a nuestro",
  57: "usuarios",
  58: "Empiece hoy",
  59: "Escanear",
  60: "descargar",
  61: "la aplicación",
  62: "Únete a nuestro",
  63: "usuarios",
  64: "Empiece hoy",
  65: "Comprar criptomonedas",
  66: "Compre BTC, ETH y otras criptomonedas fácilmente mediante transferencia bancaria.",
  67: "Compre BTC, ETH y otras criptomonedas fácilmente",
  68: "con",
  69: "Alertas de precios",
  70: "Reciba notificaciones sobre los precios de BTC, ETH, XRP y más.",
  71: "Compra recurrente",
  72: "Haga crecer su cartera automáticamente con operaciones diarias, semanales o mensuales.",
  73: "Apuestas en cadena",
  74: "Generar ingresos pasivos ayudando",
  75: "para asegurar blockchains.",
  76: "Únete a nuestro",
  77: "usuarios",
  78: "Empiece hoy",
  79: "Lo sentimos, su navegador no admite videos incrustados.",
  80: "TARJETA VISA CRYPTO.COM",
  81: "La única tarjeta criptográfica que necesitas",
  82: "Disfruta de hasta un 5% de reembolso en todos tus gastos con tu elegante tarjeta de metal puro.",
  83: "Sin tarifas anuales. Recarga con moneda fiduciaria o criptográfica.",
  84: "Elige tu tarjeta",
  85: "GANAR CRYPTO",
  86: "Saca el máximo partido a tus bienes, de forma segura",
87: "Elija entre más de 21 criptomonedas, incluidas Bitcoin y monedas estables.",
  88: "Calcular recompensas",
  89: "INTERCAMBIO",
  90: "Opere con bitcoins y otras criptomonedas con confianza en el intercambio de criptomonedas más rápido y seguro del mundo",
  91: "ESCRITORIO",
  92: "Ir a Crypto.com Exchange",
  93: "MÓVIL",
  94: "Descargar la aplicación Exchange",
  95: "Liquidez",
  96: "Liquidez profunda de la cartera de pedidos en todas las condiciones del mercado",
  97: "Velocidad",
  98: "Motor de coincidencia de 64 millones de TPS",
  99: "latencia central de 370 nanosegundos",
  100: "Seguridad",
  101: "Marca de confianza de protección de datos de Singapur",
  102: "NUESTRA VISIÓN",
  103: "Criptomonedas en cada billetera™",
  104: "Fundada en",
  105: "Usuarios",
  106: "Acerca de nosotros",
  108: "Precio",
  107: "Preguntas frecuentes",
  109: "¿Qué es la criptomoneda?",
  110: "La criptomoneda es una moneda digital o virtual que opera con tecnología de contabilidad distribuida llamada ",
  111: "cadena de bloques",
  112: "y usos",
  113: "criptografía",
  114: "por seguridad. Está descentralizado y opera independientemente de un banco central.",
  115: "A diferencia de las monedas tradicionales, las criptomonedas no están respaldadas por un producto físico o un gobierno, y su valor está determinado por la oferta y la demanda del mercado. Las criptomonedas se pueden utilizar para comprar bienes y servicios, transferir fondos y comerciar en los mercados. Las criptomonedas populares incluyen ",
  116: "Bitcoin",
  117: "terio",
  118: "Ondulación",
  119: "y",
  120: "Muchas criptomonedas, como Bitcoin, se crean mediante un proceso llamado",
  121: "minería",
  122: "que implica resolver ecuaciones matemáticas complejas para validar y registrar transacciones en una cadena de bloques. Este mecanismo también se llama",
  123: "Prueba de trabajo (PoW)",
  124: ". Otro mecanismo de consenso que ha ganado popularidad, ya que es más eficiente energéticamente, es",
  125: "Prueba de participación (PoS)",
  126: ". En lugar de minar, PoS depende de que los participantes de la red validen las transacciones. Ethereum, la segunda criptomoneda más grande, utiliza este mecanismo de consenso.",
  127: "Dónde comprar criptomonedas",
  128: "Hay varias formas de comprar criptomonedas, entre ellas:",
  129: "Servicios de intermediación:",
  130: "Los corredores de criptomonedas permiten a los usuarios simplemente comprar y vender criptomonedas. Un ejemplo popular es el",
  131: ", en el que confían más de 100 millones de usuarios. Está disponible en Apple Store y Google Play.",
  132: "Intercambios de criptomonedas:",
  133: "Estas son plataformas en línea donde los usuarios pueden comprar, vender e intercambiar criptomonedas utilizando moneda fiduciaria u otras criptomonedas. Ofrecen funciones más complejas en comparación con un corretaje de criptomonedas, agregando instrumentos comerciales como derivados de criptomonedas. El ",
  134: "Intercambio",
  135: "es un ejemplo de un intercambio de cifrado popular.",
  136: "Mercados de igual a igual (P2P):",
  137: "Estas son plataformas donde los compradores y vendedores pueden intercambiar criptomonedas directamente sin la participación de un intercambio de terceros. Esto también se conoce como",
  138: "abreviatura de finanzas descentralizadas. Se puede acceder a múltiples mercados criptográficos P2P, todo en una sola aplicación a través de",
  139: "Monedero DeFi",
  140: "Es importante realizar una investigación adecuada y elegir una plataforma confiable para comprar criptomonedas. Por ejemplo, Crypto.com tiene el ",
  141: "calificación de seguridad más alta de la industria",
  142: "Además, es recomendable almacenar las criptomonedas de forma segura en una billetera como la aplicación Crypto.com o la billetera DeFi Crypto.com.",
  143: "Cómo comprar criptomonedas",
  144: "Para comprar criptomonedas, sigue estos pasos generales",
  145: "Elija una plataforma criptográfica para usar, como ",
  146: "o",
  147: "Cree una cuenta en la plataforma elegida proporcionando información personal y verificación de identidad, también conocida como 'Conozca a su cliente'",
  148: "procedimientos",
  149: "Deposite moneda fiduciaria u otra criptomoneda en la cuenta recién creada. La aplicación Crypto.com admite transferencias bancarias, tarjetas de crédito, tarjetas de débito y transferencias de criptomonedas para comprar criptomonedas, según la región.",
150: "Navegue a la sección 'Comprar' de Crypto.com Exchange o App y seleccione la criptomoneda a comprar",
  151: "Ingrese la cantidad de criptomoneda a comprar y confirme la transacción",
  152: "La criptomoneda se depositará en la cuenta. Desde aquí, se puede transferir a otras billeteras criptográficas o convertir nuevamente a moneda fiduciaria y pagar a una cuenta bancaria",
  153: "Es importante realizar una investigación adecuada y elegir una plataforma confiable para comprar criptomonedas. Por ejemplo, Crypto.com tiene el ",
  154: "calificación de seguridad más alta de la industria",
  155: "Además, es recomendable almacenar las criptomonedas de forma segura en una billetera como la ",
  156: "o",
  157: "Cómo operar con criptomonedas",
  158: "Para operar con criptomonedas, siga estos pasos generales",
  159: "Elija un intercambio de criptomonedas que admita el comercio. Una opción popular es",
  160: "Crea una cuenta en la plataforma elegida y realiza la verificación de identidad, conocida como",
  161: "Deposite fondos en la cuenta recién creada utilizando un método de pago compatible. Crypto.com Exchange admite transferencias bancarias y tarjetas de crédito/débito.",
  162: "Navegue a la sección de operaciones de la plataforma y seleccione el par de criptomonedas para operar",
  163: "Elija si desea comprar o vender la criptomoneda e ingrese el monto a negociar",
  164: "Establezca el precio preferido y el tipo de orden. Hay varios tipos de órdenes, incluidas órdenes de mercado, órdenes limitadas, órdenes stop y opciones criptográficas, que permiten a los usuarios comprar o vender a un precio específico o bajo ciertas condiciones",
  165: "Envíe la orden comercial y espere a que se ejecute. Dependiendo de las condiciones del mercado, la operación puede completarse inmediatamente o puede llevar tiempo completarse",
  166: "Supervisar las operaciones y ajustar las estrategias según sea necesario",
  167: "Aquí hay una introducción a",
  168: "Es fundamental tener en cuenta que el comercio de criptomonedas conlleva riesgos y es importante comerciar sólo con lo que pueda permitirse perder",
  169: "Monedero DeFi",
  170: "('Conozca a su cliente')",
  171: "negociar en Crypto.com Exchange",
  172: "Cómo ganar criptomonedas",
  173: "Hay varias formas de ganar criptomonedas, incluidas",
  174: "Minería",
  175: ": La minería de criptomonedas implica el uso de hardware informático especializado para resolver ecuaciones matemáticas complejas que validan las transacciones en una red blockchain. Los mineros exitosos son recompensados ​​con criptomonedas recién acuñadas por sus esfuerzos.",
  176: "Replanteo/Bloqueos",
  177: "Las apuestas y los bloqueos implican mantener o bloquear una cierta cantidad de criptomonedas en una billetera o en una plataforma para respaldar las operaciones de la red blockchain. Los apostadores son recompensados ​​con nuevas criptomonedas como una forma de interés por su apoyo",
178: "Comercio",
  179: "El comercio de criptomonedas implica comprar y vender criptomonedas en bolsas u otras plataformas comerciales. Aquellos que tienen un buen conocimiento de las tendencias del mercado y son capaces de tomar decisiones comerciales informadas pueden obtener ganancias mediante el comercio.",
  180: "Airdrops: Los Airdrops son distribuciones gratuitas de criptomonedas para usuarios que cumplen con ciertos criterios o participan en actividades promocionales.",
  181: "Proyectos",
  182: "Algunos proyectos de blockchain ofrecen recompensas o recompensas para los usuarios que contribuyen a su desarrollo o comunidad. Esto puede incluir actividades como recompensas por errores, pruebas o creación de contenido.",
  183: "Es importante tener en cuenta que cada método de ganar criptomonedas conlleva sus propios riesgos y recompensas. Se recomienda investigar cuidadosamente las criptomonedas y comprender el proceso antes de comprarlas. Obtenga más información sobre el mercado de las criptomonedas",
  184: "Cómo ganar recompensas con criptomonedas",
  185: "Los usuarios pueden ganar recompensas por sus tenencias de criptomonedas a través de varios productos que ofrecen cuentas con recompensas o servicios de préstamo. Aquí hay algunas formas de ganar recompensas por criptomonedas:",
  186: "El",
  187: "Intercambio",
  188: "y",
  189: "Monedero DeFi",
  190: "todos ofrecen diferentes formas de ganar recompensas en criptomonedas, llamadas",
  191: "Cree una cuenta en la plataforma elegida y deposite las tenencias de criptomonedas en la cuenta de recompensas.",
  192: "Dependiendo de la plataforma, los usuarios pueden ganar recompensas prestando su criptomoneda a otros usuarios o bloqueando su criptomoneda por un período de tiempo.",
  193: "Algunos productos ofrecen tasas de recompensa fijas (por ejemplo, ",
  194: "Tarjeta Visa",
  195: "mientras que otros pueden ofrecer tasas variables que dependen de las condiciones del mercado (por ejemplo, la función 'Ganar' en Crypto.com DeFi Wallet)",
196: "Supervise las recompensas y ajuste las estrategias según sea necesario.",
  197: "Es importante tener en cuenta que ganar intereses y recompensas con criptomonedas conlleva riesgos, incluidas las fluctuaciones en las condiciones del mercado que pueden afectar las tasas de interés. Asegúrese de investigar cuidadosamente y comprender los términos y condiciones antes de depositar criptomonedas",
198: "Tienes más preguntas",
  199: "Contáctenos",
  200: "Comience con las criptomonedas",
  201: "Escanear para descargar",
  202: "Características",
  203: "Recompensas",
  204: "Compra y Venta",
  205: "Apuestas en cadena",
  206: "Pagar",
  207: "Sobrealimentador",
  208: "Arena comercial",
  209: "Aprende",
  210: "Universidad",
  211: "Investigación y Análisis",
  212: "Glosario",
  213: "¿Qué es Ethereum?",
  214: "¿Qué es Blockchain?",
  215: "¿Cómo comprar Bitcoin?",
  216: "¿Cómo comprar Ethereum?",
  217: "¿Cómo comprar criptomonedas?",
  218: "¿Qué es Cripto?",
  219: "¿Qué es DeFi?",
  220: "Precios",
  221: "Convertidor",
  222: "Widgets del sitio",
  223: "Cuáles son las tendencias",
  224: "Actualizaciones del mercado",
  225: "Novedades del producto",
  226: "Noticias de la empresa",
  227: "Eventos",
  228: "Empresa",
  229: "Acerca de nosotros",
  230: "Socios",
  231: "Seguridad",
  232: "Prueba de Reservas",
  233: "Clima",
  234: "capital",
  235: "Afiliado",
  236: "Carreras",
  237: "Listado",
  238: "Apoyo",
  239: "Características",
  240: "Recompensas",
  241: "Compra y Venta",
  242: "Ganancia criptográfica",
  243: "Apuestas en cadena",
  244: "Pagar",
  245: "Pagar por negocios",
  246: "Negociación de margen",
  247: "Negociación de derivados",
  248: "Sobrealimentador",
  249: "Arena comercial",
  250: "Productos",
  251: "El propósito de este sitio web es únicamente mostrar información sobre los productos y servicios disponibles en la aplicación Crypto.com. No pretende ofrecer acceso a ninguno de dichos productos y servicios. Usted puede obtener acceso a dichos productos y servicios en la aplicación Crypto.com.",
  252: "Tenga en cuenta que la disponibilidad de los productos y servicios en la aplicación Crypto.com está sujeta a limitaciones jurisdiccionales. Es posible que Crypto.com no ofrezca ciertos productos, características y/o servicios en la aplicación Crypto.com en ciertas jurisdicciones debido a restricciones regulatorias potenciales o reales.",
  253: "Todos los derechos reservados.",
  254: "Aviso de Privacidad",
   255: "Estado",
  256: "Preferencias de cookies",
  257: "Sugerido para ti",
  258: "Global",
  259: "Todas las ubicaciones",
  260: "Estados Unidos",
  261: "Espacio Económico Europeo",
  262: "Singapur",
  263: "Brasil",
  264: "Australia",
  265: "Türkiye",
  266: "Francia",
  267: "Canadá",
  268: "Italia",
  269: "Corea",
  270: "Reino Unido",
  271: "Emiratos Árabes Unidos",
  272: "comprobar el precio de las criptomonedas",
  273: "conozca las medidas de protección líderes de nuestra industria",
  274: "explorando nuestra cartera de socios de clase mundial",
  275: "lee nuestros titulares",
  276: "seguimiento de los últimos desarrollos del mercado",
  277: "nuestras últimas actualizaciones y lanzamientos",
  278: "conexión en línea y fuera de línea",
  279: "construyendo tu conocimiento de cifrado",
  280: "obtener opiniones líderes de nuestra industria",
  281: "todo lo que necesitas saber sobre bitcoin",
  282: "interpretación de términos criptográficos",
  283: "empresas y actualizaciones de productos",
  284: "ecosistema",
  285: "sobre",
  286: "descubrir nuestra visión, Misión y equipo",
  287: "construyendo Web3 con nosotros",
  289: "defi se vuelve simple",
  290: "tu llave, tu criptomoneda",
  291: "defi gana dinero",
  292: "sin cerradura regular, el rendimiento es estable",
  293: "intercambio defi",
  294: "intercambiar tokens defi y triplicar las ganancias",
  295: "consigue la billetera defi",
  296: "explorando la función defi",
  297: "comercio",
  298: "más de 20 monedas de curso legal",
  299: "y Apple / Google pay",
  300: "líder",
  301: "cumplimiento regulatorio",
  302: "y",
  303: "certificación de seguridad",
  304: "confianza",
  305: "más de 100 millones de usuarios",
  306: "global",
  307: "Comercio",
  308: "Ethereum",
  309: "El sitio web y la aplicación Crypto.com Korea en la jurisdicción de la República de Corea son proporcionados por Foris DAX Korea Limited.",
  310: "Foris DAX Korea Limited",
  311: "Suite 802, 8F, Jongno 3gil 17, Jongno gu, Seúl, Corea del Sur",
  312: "Director ejecutivo: Eric Anziani",
  313: "Número de registro comercial: 885-88-00694",
  314: "Información de contacto: 1588-9520",
  315: "Chat en vivo",
  316: "Descargar la aplicación Exchange",
  317: "Frase de restauración de copia de seguridad",
  318: "Tu frase de recuperación se mostrará la próxima vez. Asegúrate antes de continuar",
  319: "Ahora estoy en un lugar de alta privacidad y puedo empezar a escribir frases de recuperación",
  320: "Confirmo que entiendo que si se pierde la frase de recuperación, perderé la billetera y sus fondos",
  321: "No compartiré frases de recuperación con nadie, ni online ni offline",
322: "Mostrar mi frase de recuperación",
    323: "Nunca se te pedirá tu frase de recuperación. Guarda tu frase de recuperación de forma segura y no la compartas con nadie",
    324: "Continuar el logro",
  325: "Escribe las siguientes palabras en orden numérico 12 y guarda la frase de recuperación en un lugar seguro",
  326: "Mi frase de recuperación"
}
